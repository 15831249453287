@import "../../../../styles/variables.scss";

.container {
    .backToSummary {
        margin: 24px;
        cursor: pointer;

        span {
            margin-left: 8px;
            color: $link-color;
        }
    }
}
