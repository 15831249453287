.container {
    margin: 10 0 0 0;

    .pagination {
        margin: 10px 0 0 0;
    }

    .noResults {
        display: flex;
        justify-content: center;
    }

    .kpiCount {
        width: 90%;
        text-align: right;
    }

    .kpiHeading {
        display: flex;
    }

    .textContainer {
        width: 100%;
        display: flex;

        .content {
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
