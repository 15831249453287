@import "../../styles/variables.scss";

.container {
    width: 100%;
    margin: 10px;
}
.heading {
    display: flex;
    padding: 12px 16px 0;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
}
.description {
    padding-left: 16px;
    padding-top: 20px;
}

.tabStyling {
    border-bottom: 1px solid $border-color;
}

.analysisPage {
    max-height: 100%;
    overflow: scroll;
}
