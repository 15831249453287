@import "../../../styles/variables.scss";
.noResult {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;

    .info {
        font-size: $font-size-xl;
        line-height: 1.15;
        letter-spacing: 0.015em;
        margin: 34px 0 16px;
    }
}

.pagination {
    margin-top: 40px;
}
.tableField {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.noData {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
}

.progress-text {
    margin-left: 5px;
    vertical-align: middle;
    font-size: 9px;
}
