@import "../../styles/variables.scss";

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid $border-color;
    height: 69px;

    .headerText {
        font-size: $font-size-large;
        line-height: 1.16;
        letter-spacing: 0.015em;
        font-weight: bold;
    }
}
