@import "../../styles/variables.scss";

.container {
    display: flex;
    align-items: center;

    .message {
        margin-left: 8px;
        color: $error;
    }
}
