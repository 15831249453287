@import "../../../styles/variables.scss";

.container {
    .heading {
        display: flex;
        padding: 30px 24px 15px;
        border-bottom: 1px solid $border-color;
    }

    .form {
        padding: 24px;
        padding-top: 5px;

        .row {
            margin-bottom: 5px;

            .highlightKpis,
            .allScannedDocs {
                display: flex;
                align-items: center;

                img {
                    margin: 0.4rem 0.7rem 0rem;
                }
                margin: 0.4rem 4rem 0 0.4rem;
            }
        }
    }
    .footer {
        display: flex;
        justify-content: space-between;
        padding: 0 24px 10px;
        align-items: center;

        .note {
            &::before {
                content: "*";
                color: $error;
            }
        }

        .actions {
            display: flex;
            .btnWrapper {
                margin-left: 16px;
                .mds-button {
                    width: 158px;
                }
            }
        }
    }
}

.label {
    padding: 10px 4px 16px;
}

.error {
    color: $error;
    margin-left: 24px;
    font-size: $font-size-regular;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}
.errorIcon {
    margin-left: 8px;
    margin-top: 4px;
}
.errorMsgSpace {
    margin-left: 4px;
}
