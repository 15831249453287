@import "../../styles/variables.scss";

.container {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;

    .projectName {
        line-height: 1.16;
        letter-spacing: 0.015em;
    }

    .heading {
        display: flex;
        padding: 12px 16px 0;
        justify-content: space-between;
        border-bottom: 1px solid $border-color;
    }

    .tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $border-color;
    }
}

.documentList {
    height: 100%;
    margin-bottom: 40px;
}

.customHeader {
    display: flex;
    align-items: center;
}
.downArrow {
    margin-left: 8px;
}

.label,
.downArrow {
    cursor: pointer;
}

.clearFilter {
    cursor: pointer;
}
.btnContainer {
    display: flex;
    .configureBtn {
        margin-left: 610px;
    }
}

.pendingIcon,
.inprogressIcon,
.successIcon,
.warningIcon,
.errorIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    color: $white;
    border-radius: 50%;
    margin-right: 8px;
}
.successIcon {
    background-color: #179369;
}
.warningIcon {
    background-color: orange;
}
.errorIcon {
    background-color: red;
}
.inprogressIcon {
    color: green;
}
.pendingIcon {
    color: black;
}

.extractionStatus {
    display: flex;
    align-items: center;
}
