@import "../../styles/variables.scss";

.container {
    font-size: $font-size-regular;
    line-height: 1.14;
    display: flex;

    .key {
        margin-right: 4px;
    }
}
