@import "../../styles/variables.scss";
.container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;
    .error {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 90px;

        .info {
            font-size: $font-size-xl;
            line-height: 1.16;
            letter-spacing: 0.015em;
        }
    }
}

.deleteProject {
    padding: 24px 0;
    display: flex;
    align-items: center;

    .message {
        margin-left: 8px;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        color: $white;
        background-color: orange;
        border-radius: 50%;
        margin-right: 8px;
    }
}
