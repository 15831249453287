@import "./styles/variables.scss";

.container {
    height: 100%;

    .content {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 124px);
        .main {
            display: flex;
        }

        .rightSide {
            width: 100%;
            margin-bottom: 80px;
            max-height: calc(100vh - 124px);
        }
    }
}
