@import "../../../styles/variables.scss";
.confidence {
    color: $error;
}
.icon {
    cursor: pointer;
    margin: 6px;
}

.tableIcon {
    margin: 8px;
}

.pagination {
    margin-top: 40px;
}

.documentHeader {
    display: flex;
}
.noResults {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
}
.tableField {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hideTableIcon {
    visibility: hidden;
}
