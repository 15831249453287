@import "../../../../styles/variables.scss";

.doc-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    .list-header,
    .list-body {
        display: flex;

        .name {
            width: 60%;
        }
        .size {
            width: 40%;
            text-align: end;
        }
    }

    .list-body {
        flex-direction: column;

        .row {
            display: flex;
            padding: 12px 32px 12px 0;
            border-bottom: 1px solid $border-color;
            font-size: $font-size-regular;
            color: $black-info;

            .remove {
                border: none;
                background: none;
                cursor: pointer;
                vertical-align: middle;
                margin-left: 20px;
            }
        }

        .accepted-files {
            .row {
                &:last-child {
                    border: none;
                }
            }
        }

        .rejected-files {
            .row {
                color: $error;

                .remove {
                    color: $grey;
                }
            }
        }
    }

    .list-header {
        align-items: center;
        font-weight: $font-weight-medium;
        font-size: $font-size-medium;
        border-bottom: 1px solid $border-color;
        margin: 0 -16px;
        padding: 12px 16px;
        color: $black;

        .size {
            padding-right: 64px;
        }
    }
}
