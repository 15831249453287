.container {
    width: 100%;
    display: flex;

    .content {
        width: 80%;
        margin-left: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
