@import "../../../src/styles/variables.scss";
blockquote {
    border-left: 2px solid $toolbar-color;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: $blockquote-color;
    font-style: italic;
}
table,
th,
td {
    border: 1.5px solid $black;
    margin-bottom: 40px;
    margin-top: 40px;
}
table {
    border-collapse: collapse;
    margin-left: 30px;
}

.editorWrapper {
    height: calc(100vh - 295px);
    overflow-y: auto;
    font-family: "McKinsey Sans";
    background: $white;
    border-bottom: 1px solid $border-bottom-color;
    border: 1px solid $border-color;
}
table {
    width: 80%;
}
td {
    height: 30px;
    width: 30px;
}
.popup-wrapper {
    display: inline;
    position: relative;
}
.popup {
    position: absolute;
    left: 0;
    background-color: $white;
    padding: 6px 10px;
    border: 1px solid $grey;
    height: fit-content;
    z-index: 1;
}

code {
    font-family: "McKinsey Sans";
}

html {
    scroll-behavior: smooth;
    overflow-y: scroll;
}

.docContainer {
    height: calc(100vh - 295px);
    display: grid;
    grid-template-columns: 50% 50%;
    background: $white;
    border-bottom: 1px solid $border-bottom-color;
    border: 1px solid $border-color;
}

.labels {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 12px 16px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.06;
    background: $heading-background;
}
.original,
.extracted {
    text-align: center;
}
