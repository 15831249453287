@import "../../styles/variables.scss";

.container {
    padding-left: 62px;

    position: fixed;
    left: 0;
    bottom: 0;
    height: 62px;
    width: 100%;
    background-color: $nav-bg-color;
    color: #ffffff;
}
