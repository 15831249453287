@import "../../../styles/variables.scss";

.noResult {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    .info {
        font-size: $font-size-xl;
        line-height: 1.16;
        letter-spacing: 0.015em;
        margin: 34px 0 16px;
        font-weight: bold;
    }
}

.projectNamePointer {
    cursor: pointer;
    width: 100%;
}

.inactiveProject {
    width: 100%;
    color: $disabled-color;
}

.tableField {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.toolTip {
    display: inline-grid;
}
