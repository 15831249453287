@import "../../../styles/variables.scss";

.container {
    display: flex;
    flex-direction: column;

    .warning {
        display: flex;
        align-items: center;
        margin-top: 24px;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 24px;
            color: $white;
            background-color: orange;
            border-radius: 50%;
            margin-right: 8px;
        }
    }
}
