// Colors

$border-color: #dedede;
$black: #000;
$black-info: #333333;
$grey: #4d4d4d;
$error: #f13a2c;
$nav-bg-color: #051c2c;
$white: #ffffff;
$input-border-color: #b3b3b3;
$modal-cover-bg-color: #00000080;
$hover-color: #2251ff0d;
$chip-bg-color: #e5e5e5;
$input-chips-container-color: #b3b3b3;
$language-error-color: #027ab1;
$mute-color: #6c757d;
$highlight-color: yellow;
$disabled-color: #ccc;
$link-color: #2251ff;
$heading-background: #f0f0f0;
$toolbar-color: #ddd;
$editor-wrapper-box: #ededed;
$blockquote-color: #aaa;
$border-bottom-color: #dedede;

// Font Weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Font Size
$font-size-small: 12px;
$font-size-regular: 14px;
$font-size-medium: 16px;
$font-size-medium-large: 18px;
$font-size-large: 20px;
$font-size-xl: 24px;
