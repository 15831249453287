@import "../../../../styles/variables.scss";

.container {
    border: 1px solid $input-chips-container-color;
    height: 80px;
    display: flex;
    padding: 5px 12px 5px;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-y: auto;
}
.errorMsg {
    color: $error;
    font-size: $font-size-regular;
    line-height: 2.14;
    margin-right: 4px;
    padding-right: 2px;
}
.errorIcon {
    margin-left: 8px;
    margin-top: 4px;
}

.errorMsgSpace {
    margin-left: -24px;
}
