@import "../../../styles/variables.scss";

.container {
    .backToSummary {
        margin: 24px;
        cursor: pointer;
        span {
            margin-left: 8px;
            color: $link-color;
        }
    }
    .documentName {
        padding: 12px 16px;
        font-weight: bold;
        font-size: $font-size-medium;
        line-height: 1.06;
        background: $heading-background;
    }
    .text {
        padding: 20px 16px;
        max-height: calc(100vh - 400px);
        overflow: auto;
    }
    .expand {
        margin-right: 8px;
    }
    .entitySet {
        padding: 16px;
        display: flex;
        align-items: center;
    }
    .hideEntities {
        visibility: hidden;
    }
    .documentTools {
        display: flex;
        justify-content: center;
    }
}
