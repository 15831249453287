@import "../../../../styles/variables.scss";

.container {
    display: flex;

    .pdf {
        width: 50%;
    }

    .kpis {
        width: 50%;
        flex: 1;

        .kpiDetail {
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                justify-content: space-between;

                .heading {
                    font-size: 18px;
                }

                .saveBtn {
                    min-width: 50px !important;
                    min-height: 20px !important;
                    margin-right: 4rem;
                }
            }

            .kpiContainer {
                display: flex;

                .kpiRow {
                    display: flex;
                    gap: 1rem;
                    align-items: center;

                    .legend {
                        border: 1px solid grey;
                        margin: 5px;
                        width: 20px !important;
                    }

                    .text {
                        display: inline-block;
                        width: 300px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        text-align: left;
                    }
                }

                .kpiForm {
                    display: flex;

                    .kpiInput {
                        .input {
                            width: 250px !important;
                        }
                    }

                    .kpiSave {
                        display: flex;
                    }
                }
            }
        }
    }
}

.backToSummary {
    margin: 24px;
    cursor: pointer;
    span {
        margin-left: 8px;
        color: $link-color;
    }
}

.saveKPIChanges {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
