@import "../../styles/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    min-width: 576px;

    .heading {
        padding: 24px 24px 16px;
        margin: 0;
        border-bottom: 1px solid $border-color;
    }

    .body {
        padding: 24px;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        padding: 12px 24px 24px;

        .action {
            margin-left: 16px;
        }
    }
}
