@import "../../styles/variables.scss";

.header {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    border: 1px solid $border-color;

    .assetName {
        display: block;
        font-size: $font-size-xl;
        font-weight: $font-weight-bold;
    }
    .brand {
        display: block;
        font-size: $font-size-regular;
        font-weight: $font-weight-light;
    }
}
