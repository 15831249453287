@import "../../../../styles/variables.scss";

.container {
    width: 100%;
    height: 100%;

    .failure {
        display: flex;
        align-items: center;
        padding: 16px;

        .message {
            margin-left: 8px;
            color: $error;
        }
    }

    .output {
        height: calc(100vh - 192px);
        overflow-y: auto;
        text-align: justify;

        .backToExtractedFiles {
            margin: 18px;
            cursor: pointer;
            span {
                margin-left: 8px;
                color: $link-color;
            }
        }
    }
}

.inputFile {
    height: calc(100vh - 295px);
    overflow-y: auto;
    background: $white;
    border-bottom: 1px solid $border-bottom-color;
    border: 1px solid $border-color;
}

.closeBtn {
    padding-left: 24px;
}

.pdfProgress {
    position: absolute;
    top: 40%;
    left: 40%;
    background-color: #fff;
    padding: 12px;
}
