.container {
    margin: 10 0 0 0;
    .pagination {
        margin: 10px 0 0 0;
    }
    .noResults {
        display: flex;
        justify-content: center;
    }
    .entityCount {
        width: 90%;
        text-align: right;
    }
    .reportBtn {
        margin: -5px 0 0 75px;
        justify-content: center;
    }
    .extractBtn {
        margin-left: -92px;
        margin-bottom: -35px;
        margin-top: 8px;
    }
    .extractBtnDisabled {
        margin-top: 8px;
        margin-right: 20px;
        margin-left: 75px;
    }
    .extractBtnPartialExtraction {
        margin-bottom: -35px;
        margin-top: 8px;
        margin-right: 20px;
        margin-left: -95px;
    }
    .expand {
        margin-right: 8px;
    }
    .entitySet {
        padding: 8px;
        display: flex;
        align-items: center;
        margin-top: -35px;
    }
    .extractBtnText {
        margin-left: -60px;
    }
    .entityHeading {
        display: flex;
    }
    .hideEntities {
        visibility: hidden;
    }
    .entityDisabled {
        margin-bottom: 8px;
    }
    .extractInProgressMsg {
        display: flex;
        justify-content: center;
    }
    .textContainer {
        width: 100%;
        display: flex;

        .content {
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
