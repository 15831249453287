.imageFile {
    max-width: 100%;
}

.btn {
    min-width: 25px !important;
    min-height: 25px !important;
}

.labels {
    display: flex;
}
