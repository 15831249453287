@import "../../../styles/variables.scss";

.container {
    margin: 10px 0 0 0;
}

hr {
    background-color: $border-color;
    height: 1px;
    border: 0;
    margin: 10px 0 15px 0;
}
.info {
    margin: 15px 0 0 0 !important;
    .caption {
        color: $mute-color;
        font-size: large;
        font-weight: bold;
        margin: 0 0 0 5px;
        vertical-align: bottom;
    }

    .resultCount {
        color: $mute-color;
        font-size: large;
        font-weight: bold;
        margin: 0 0 0 10px;
    }
}

.pagination {
    margin: 10px 0 0 0;
}

.htmlContent {
    width: 100%;
    white-space: initial;
    b {
        background-color: $highlight-color;
    }
}

.pageNumbers {
    width: 100%;
    display: flex;

    .content {
        margin-left: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
