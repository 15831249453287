@import "./styles/variables.scss";

@font-face {
    font-family: "McKinsey Sans";
    font-weight: normal;
    src: local("McKinsey Sans"),
        url("./fonts/McKinseySans-Regular.woff") format("woff"),
        url("./fonts/McKinseySans-Regular.woff2") format("woff2"),
        url("./fonts/McKinseySans-Regular.eot") format("embedded-opentype");
}

html,
body,
#root {
    height: 100%;
}

/* react-pdf style overrides */
.react-pdf__Page__canvas {
    margin: 0 auto !important;
}

.react-pdf__Document {
    display: flex;

    .react-pdf__Page {
        .react-pdf__Page__textContent {
            top: 0 !important;
            left: 0 !important;
            transform: none !important;
        }

        .react-pdf__Page__annotations {
            position: absolute;
            top: 0 !important;
            left: 0 !important;
            transform: none !important;

            section {
                position: absolute;
                top: 0px;
                left: 0px;
            }

            section > a {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}

body {
    margin: 0;
    font-family: "McKinsey Sans", "Roboto";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

#root {
    .mds-check {
        width: 24px;
        height: 24px;
    }
    .mds-condensed-footer {
        display: flex;
        &::after {
            content: "Powered by Platform McKinsey";

            margin-right: 45%;
            color: $white;
        }
        .mds-footer-text-link {
            color: $white;
        }
        .mds-footer-logo {
            display: none;
        }

        div {
            & :first-child {
                padding-right: 5px;
                display: flex;
                margin-left: -24px;
                color: $white;
                margin-top: 0px;
            }
        }
    }
    .mds-check,
    .mds-button,
    .mds-input {
        &.mds-valid {
            border-color: $input-border-color;
        }

        &.mds-error {
            border-color: $error;
        }
    }
    .search-download {
        display: flex;
        img {
            margin-top: 8px;
        }
        .mds-button {
            justify-content: center;
            margin-left: 70px;
        }
    }
    .mds-button {
        min-width: 158px;
        min-height: 40px;
    }
    .mds-tab-list {
        height: 3.25rem;
        margin-left: 0px;

        .mds-item-text {
            font-family: "McKinsey Sans";
            font-size: $font-size-medium-large;
            line-height: 1.15;
            letter-spacing: 0.015em;
            padding-bottom: 16px;
            font-weight: 600;
            margin-left: 16px;
        }
    }
    .mds-form-el-label {
        line-height: 1.875;
    }
    .mds-form-el-validation-message {
        color: $error;
    }
    .mds-select-wrapper {
        padding: 5px 16px 5px 12px;
    }
    select {
        background: $white;
        box-shadow: 0px 4px 8px #2251ff33;
    }
    .mds__menu-list {
        font-size: $font-size-medium;
        line-height: 30px;
        color: #757575;
    }
    .required {
        .mds-form-el-label {
            &::after {
                content: "*";
                color: $error;
            }
        }
    }
    .mds__option,
    .mds__option--is-focused {
        box-shadow: none;
    }
    .mds__option {
        padding-left: 8px;
        svg {
            display: none;
        }
    }

    .email {
        .mds-form-el {
            div {
                margin: 0;
                .mds-input {
                    border: none;
                    width: 360px;
                    padding: 0;
                    height: 24px;
                }
            }
        }
    }

    .mds-table-odd-row,
    .mds-table-even-row {
        &:hover {
            background: $hover-color;

            .project-list-actions {
                &.isAdmin {
                    visibility: visible;
                }
            }
        }
        .project-list-actions {
            display: flex;
            visibility: hidden;

            .action {
                margin-right: 36px;
                border: none;
                background: none;
                cursor: pointer;
                vertical-align: middle;
            }
        }
    }

    .breadcrumb {
        ol {
            height: auto;
            li {
                font-size: $font-size-large;
                line-height: 1.16;
                letter-spacing: 0.015em;
                font-weight: bold;

                a:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .mds-regular-pagination-next-button,
    .mds-regular-pagination-prev-button {
        svg {
            display: none;
        }
    }
    .mds-regular-pagination-next-button {
        &::after {
            content: "";
            border: solid $grey;
            border-width: 0 1px 1px 0;
            padding: 5px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            margin-right: 5px;
        }
    }

    .mds-regular-pagination-prev-button {
        &::before {
            content: "";
            border: solid $grey;
            border-width: 0 1px 1px 0;
            padding: 5px;
            margin-left: 5px;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }
    }

    .mds-tab-focus-square {
        border: none;
    }
}

.mds-dd-dropdown-menu {
    background: $white;
    padding: 0 !important;
    :nth-child(1) {
        font-size: $font-size-regular;
    }
}

#delete-project,
#entity-modal,
#delete {
    .mds-modal-content {
        margin: 0;
    }
    .mds-modal-frame {
        padding: 0;
        min-width: 576px;
    }
    .mds-modal-close-btn {
        display: none;
    }
    .mds-modal-cover {
        background: $modal-cover-bg-color;
    }
    .mds-modal-content {
        max-height: calc(100vh - 100px);
        overflow-y: auto;
    }
}

#doc-modal,
#create-project {
    .mds-modal-content {
        margin: 0;
    }
    .mds-modal-frame {
        padding: 0;
        width: 768px;
    }
    .mds-modal-close-btn {
        display: none;
    }
    .mds-modal-cover {
        background: $modal-cover-bg-color;
    }

    .mds-modal-content {
        max-height: calc(100vh - 100px);
        overflow-y: auto;
    }

    .mds-progress-label-top {
        color: $white;
        margin-top: 16px;
        width: 300px;

        div {
            span {
                font-size: 16px;
                &:first-child {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-right: 4px;
                }
            }

            &:last-child {
                height: 1rem;
            }
        }
    }
}

.notification {
    position: absolute;
    top: 0;
    margin-top: 20px;
    transform: translateX(-50%);
    left: 50%;

    .mds-alert {
        margin-bottom: 24px;
        width: 768px;
        height: 60px;

        div {
            justify-content: left;

            .mds-alert-content {
                font-family: "McKinsey Sans Medium";
                padding: 18px 0;
            }
        }
    }
}

#analysis-caution {
    .mds-modal-content {
        margin: 0;
    }
    .mds-modal-cover {
        background: $modal-cover-bg-color;
    }
    .mds-modal-close-btn {
        display: none;
    }
    .mds-modal-frame {
        margin: 0;
        padding: 0;
        width: 576px;
    }
}
