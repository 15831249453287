@import "../../../styles/variables.scss";

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    .form {
        display: flex;
        width: 100%;
        padding: 8px;
    }
    .button {
        align-self: flex-end;
        margin: 0.5rem 2rem 0.25rem;
    }

    .results {
        width: 100%;
        padding-top: 16px;
        border-top: 1px solid $border-color;
    }
}
