@import "../../../styles/variables.scss";

.btn-upload {
    margin: 20px 0;
}

.doc-upload-modal {
    .modal-heading {
        padding: 24px 24px 16px;
        margin: 0;
        border-bottom: 1px solid $border-color;
    }

    .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px;

        .drag-drop-container {
            width: 720px;
            height: 230px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            border: 2px dashed $border-color;

            margin: 0 0 16px;

            &.no-files {
                justify-content: center;
                align-items: center;
            }
        }

        .validation-info {
            font-size: $font-size-regular;
            line-height: 1.14;
            color: $black;
            text-align: center;
            border: 1px solid $border-color;

            width: 100%;
            margin-top: 16px;
            padding: 8px 0;

            .message {
                margin: 10px 0;
                color: $black-info;
            }
        }
    }
    .footer {
        display: flex;
        justify-content: flex-end;
        padding: 12px 24px 24px;

        .btn-wrapper {
            margin-left: 16px;
            .mds-button {
                width: 158px;
            }
        }
    }
}
.error-message {
    color: $error;
    font-size: $font-size-regular;
    margin-top: 25px;
    font-weight: $font-weight-medium;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.progress-message {
    font-size: $font-size-medium-large;
    color: $white;
    margin-bottom: 12px;
}
.checkbox {
    margin-bottom: 50px;
    margin-top: 22px;
    font-size: $font-size-regular;
    font-weight: $font-weight-regular;
    display: flex;
}

.download {
    border: none;
    background: none;
    cursor: pointer;
    vertical-align: middle;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
