@import "../../styles/variables.scss";

.container {
    margin: 0 12px 6px 0;
    font-size: $font-size-medium;
    padding: 4px 12px;
    border-radius: 16px;
    background: $chip-bg-color;
    display: flex;
    align-items: center;
    height: 24px;

    .remove {
        width: 10px;
        border: none;
        background: none;
        cursor: pointer;
        vertical-align: middle;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
}
