.container {
    display: flex;
    flex-direction: column;

    .info {
        color: #6c757d;
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 16px 16px;
    }
}
