@import "../../styles/variables.scss";

.loader,
.loader-cover {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader-cover {
    background: $black;
    opacity: 0.8;
    z-index: 10002;
}
.loader {
    z-index: 10003;
}
