@import "../../styles/variables.scss";

.error {
    color: $error;
    margin-left: 24px;
    font-size: $font-size-regular;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}
.errorIcon {
    margin-left: 8px;
    margin-top: 4px;
}
