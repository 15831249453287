.container {
    width: 100%;

    .searchInput {
        margin: 5px;
    }

    .searchButton {
        margin: 12px 0 0 10px;
    }

    .info {
        margin: 20px 0 0 0;
    }
    .error {
        margin: 5px;
    }
    .toggle {
        margin: 5px;
        display: flex;
        img {
            margin: 4px 0 0 5px;
        }
    }
}
.toolTip {
    margin-left: -25px;
}
