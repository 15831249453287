.container {
    display: flex;
    .col {
        display: flex;
        flex-direction: column;
        margin: 0 20px;
    }
    .item {
        margin-top: 6px;
    }
}
