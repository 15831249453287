@import "../../../styles/variables.scss";
.container {
    width: 100%;
    display: flex;
    .heading {
        padding: 24px 24px 16px;
        margin: 0;
        border-bottom: 1px solid $border-color;
    }
    .confirmMsg {
        margin: 30px 0 24px 24px;
        font-size: $font-size-medium;
    }
    .actions {
        margin: 30px 0 0 0 !important;
    }
    .actionsContainer {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        padding: 12px 24px 24px;
    }
    .text {
        font-size: $font-size-medium;
        margin-left: 24px;
        margin-top: 26px;
    }
    .errorText {
        font-size: $font-size-medium;
        margin-left: 24px;
    }

    .largeText {
        font-size: $font-size-xl;
        margin-left: 24px;
    }
    .proceedBtn {
        margin-left: 16px;
    }
    .errorCountMsg {
        font-size: $font-size-medium;
        margin-left: 24px;
        margin-top: 10px;
    }
}
