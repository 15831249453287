@import "../../styles/variables.scss";

.leftNav {
    width: 64px;
    height: 100%;
    padding: 16px 12px;
    background: $nav-bg-color;
    height: calc(100vh - 32px);

    svg {
        color: $white;
    }
}
